import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import '../assets/main.css'
import Card from "./css-components/top-image-card.js"

import styles from './lender-preview.module.css'

export default ({ article }) => (

    <Card node={article}  link={`/reviews/${article.slug}`} />
)
