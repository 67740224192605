import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import LenderPreview from '../components/lender-preview'
import ArticlePreview from '../components/article-preview-short'
import SEO from '../components/SEO'
import '../assets/main.css'
import SideContent from '../components/side-components/article-side.js'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulLender.edges')
    const articles = get(this, 'props.data.allContentfulArticle.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    const mainContent = (
       
        <div>
            <h2 className="section-headline text-center">Working Capital Lenders</h2>
            <ul className="article-list p-0">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <LenderPreview article={node} />
                  </li>
                )
              })}
              <li className="text-center"><a href="/reviews" className="text-center" style={{textDecoration: "none"}}><button className="cursor-pointer border-none p-5 bg-green-600 hover:bg-green-800 text-white font-bold mt-1 mb-2 text-center">
                       See More Lenders
                    </button></a>
                    </li>
            </ul>
            
          </div>
       
	);

   

    return (
      <Layout location={this.props.location} heading={"Recent Articles"} apply={"Check Eligibility Here"} mainContent={mainContent} title = {"Find the Right Lender for Your Business in 60 Seconds."} image={require("../assets/img/landingpages/general-tablet.jpg")} sideContent={<SideContent articles={articles} />}>
          <Helmet title={siteTitle} />
          <SEO />
          <Hero data={author.node} />
          
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulLender(limit: 8, sort: { fields: [title], order: DESC }) {
      edges {
        node {
          title
          slug
          description {
            description
          }
          image {
            file {
                url
            }
         }
        }
      }
    }

    allContentfulArticle(limit: 7){
      edges {
        node {
          title
          description {
            description
          }
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          image {
            file {
                url
            }
}
        }
      }
    }

    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
