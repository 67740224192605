import React from "react";
import Img from "gatsby-image";
import "../../assets/main.css";
import { Link } from "gatsby";

export default ({ node, link }) => (
  <div class="w-full rounded overflow-hidden shadow-lg grid-flow-col bg-white relative mb-5">
    <div class="font-bold text-xl mb-2 p-4 bg-gray-200">{node.title}</div>
    <div className="lg:flex">
      <img
        alt="Placeholder"
        class="block h-auto w-full"
        style={{
          flexShrink: 1,
          maxWidth: "100%",
          margin: "auto",
          height: "auto",
          padding: 10,
          width: 200,
        }}
        src={node.image.file.url}
      ></img>
      <div class="px-6 py-4">
        <p class="text-gray-700 text-base">{node.description.description}</p>
      </div>
      <div class="px-6 py-4 my-auto mx-auto">
        <Link to={'https://working-capital.com/apply/'} style={{ textDecoration: "none" }}><button className="cursor-pointer whitespace-no-wrap border-none py-3 px-8 mt-3 bg-green-600 hover:bg-green-800 text-white font-bold mt-1 mb-2 text-center justify-center w-full">
          
            Get Funded
          
        </button></Link>
        <div className="mt-3 text-green-600 hover:text-green-800 font-bold hover:underline mb-2 hover:cursor-pointer text-center">
          <Link to={link} style={{ textDecoration: "none" }}>
            Read Review
          </Link>
        </div>
      </div>
    </div>
  </div>
);
